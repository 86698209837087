<template>
  <div>

    <div v-show="mostrar_financeiro" class="grid">
      <div class="col-12 lg:col-12 xl:col-12">
        <div class="card mb-0">
          <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
          <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
            optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
          </Dropdown>

          <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3" optionLabel="label"
            optionValue="value" placeholder="Selecione um Mês">
          </Dropdown>
          <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
            optionValue="value" placeholder="Selecione um Ano">
          </Dropdown>
          <Button class="ml-3" :loading="loading" @click="listaConsolidados()">Filtrar</Button>
        </div>
      </div>
    </div>

    <!-- Blocos de indicadores (inalterado) -->
    <div v-show="mostrar_blocos" class="grid">
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Planilhas Importadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_importadas }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Faturas Contestadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_validar }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-info-circle text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Faturas<br> Validadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_validadas }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check-circle text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"> </span>
        </div>
      </div>

      

      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"> Pagamentos Aprovados</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_pagamento_aprovado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>

      <!-- <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Total Aprovados</span>
              <div class="text-900 font-medium text-xl">{{ 0 }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div> -->

      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"> Pagamentos Rejeitados</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_pagamento_rejeitado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-red-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Blocos financeiros (inalterado) -->
    <div class="grid" v-show="mostrar_financeiro">
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">Total End ID</span>
              <div class="text-900 font-medium text-xl">{{ total_end_id }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">End ID Validado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_validado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">End ID Contestado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_contestado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-orange-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Solicitado</span>
              <div class="text-900 font-medium text-xl">{{ valor_total }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-blue-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Contestado</span>
              <div class="text-900 font-medium text-xl">{{ valor_contestado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-red-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-red-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Validado</span>
              <div class="text-900 font-medium text-xl">{{ valor_validado }}</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
              style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
    </div>

    <hr v-show="mostrar_blocos" />

    <!-- Tabela de planilhas para usuários fornecedor (dados provenientes do payload) -->
    <div class="col-12 xl:col-12" v-show="mostrar_blocos_fornecedor">
      <div class="card">
        <h5>Planilhas Importadas</h5>
        <DataTable filter :value="planilhas" :rows="5" :paginator="true" responsiveLayout="scroll">
          <Column field="lote_id" header="Lote ID" :sortable="true"></Column>
          <Column field="razao_social" header="Fornecedor" :sortable="true"></Column>
          <Column field="planilha_id" header="Planilha ID" :sortable="true"></Column>
          <Column field="valor_total" header="Valor Total" :sortable="true"></Column>
          <Column field="status_contestada" header="Status" :sortable="true"></Column>
          <Column field="qtd_id_aprovado" header="Qtd Aprovado" :sortable="true"></Column>
          <Column field="valor_total_aprovado" header="Valor Aprovado" :sortable="true"></Column>
          <Column field="qtd_id_reprovado" header="Qtd Reprovado" :sortable="true"></Column>
          <Column field="valor_total_reprovado" header="Valor Reprovado" :sortable="true"></Column>
          <Column field="usuario_responsavel" header="Usuário" :sortable="true"></Column>
          <Column style="width: 15%">
            <template #header>Detalhes</template>
            <template #body="slotProps">
              <Button icon="pi pi-search" @click="mostrarPlanilha2(slotProps.data, 2)" type="button"
                class="p-button-text"></Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="card" v-show="mostrar_planilha">
      <div class="grid">
        <div class="col-12">
          <div class="field m-0">
            <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
            <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
              optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
            </Dropdown>

            <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3" optionLabel="label"
              optionValue="value" placeholder="Selecione um Mês">
            </Dropdown>
            <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
              optionValue="value" placeholder="Selecione um Ano">
            </Dropdown>
            <Button class="ml-3" :loading="loading" @click="listaConsolidados()">Filtrar</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-show="mostrar_planilha">
      <h5>Planilhas Importadas por Fornecedores</h5>
      <DataTable stripedRows v-model:filters="filters" :loading="loading" :value="planilhas" :rows="5" :paginator="true"
        responsiveLayout="scroll" scrollable scrollHeight="400px"
        :globalFilterFields="['razao_social', 'mes_referencia', 'status_contestada', 'usuario_responsavel']">
        <template #loading>
          <div class="flex justify-content-center align-items-center" style="min-height: 200px;">
            <ProgressSpinner style="width:50px; height:50px;" strokeWidth="3" />
          </div>
        </template>

        <template #empty>
          Carregando dados...
        </template>

        <template #header>
          <div class="flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Buscar" />
            </span>
          </div>
        </template>

        <Column style="min-width:420px" frozen field="razao_social" header="Fornecedor" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.razao_social != null ? slotProps.data.razao_social : '' }}
          </template>
        </Column>
        <Column style="min-width:200px" field="mes_referencia" header="Mês Referência" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.mes_referencia != null ? slotProps.data.mes_referencia : '' }}
          </template>
        </Column>
        <Column style="min-width:150px" field="lote_id" header="Número do Lote" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.lote_id != null ? slotProps.data.lote_id : 0 }}
          </template>
        </Column>
        <Column style="min-width:200px" field="status_contestada" header="Status do Processo" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.status_contestada != null ? slotProps.data.status_contestada : '' }}
          </template>
        </Column>
        <Column style="min-width:200px" field="usuario_responsavel" header="Responsável" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.usuario_responsavel != null ? slotProps.data.usuario_responsavel : '' }}
          </template>
        </Column>
        <Column style="min-width:300px" field="qtd_id_aprovado" header="Quantidade ID Aprovado" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.qtd_id_aprovado != null ? slotProps.data.qtd_id_aprovado : 0 }}
          </template>
        </Column>
        <Column style="min-width:300px" field="valor_total_aprovado" header="Valor ID Aprovado" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.valor_total_aprovado != null ? slotProps.data.valor_total_aprovado : 0 }}
          </template>
        </Column>
        <Column style="min-width:300px" field="qtd_id_reprovado" header="Quantidade ID Reprovado" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.qtd_id_reprovado != null ? slotProps.data.qtd_id_reprovado : 0 }}
          </template>
        </Column>
        <Column style="min-width:300px" field="valor_total_reprovado" header="Valor ID Reprovado" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.valor_total_reprovado }}
          </template>
        </Column>
        <Column style="min-width:40px" alignFrozen="right" frozen>
          <template #body="slotProps">
            <Button icon="pi pi-search" @click="mostrarPlanilha(slotProps.data, 1)" type="button"
              class="p-button-text"></Button>
          </template>
        </Column>
      </DataTable>
    </div>


    <Dialog v-model:visible="mostrarPlanilhaModal" :style="{ width: '80%' }" header="Planilha Fornecedor" :modal="true">
      <div>
        <DataTable ref="dt" exportFilename="relatorio[BASE1]" csvSeparator=";" :value="planilha_fornecedor"
          v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]" scrollHeight="400px"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Planilha</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>
          <Column v-for="col in cabecalhoModal" :field="col.field" :header="col.header" :key="col.field"
            style="white-space:nowrap">
          </Column>
        </DataTable>
      </div>
    </Dialog>
  </div>
</template>

<script>
import EventBus from "@/AppEventBus";
import ProductService from "../service/ProductService";
import { FilterMatchMode } from 'primevue/api';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  data() {
    return {
      dadosAprovados: [],
      planilha_fornecedor: [],
      mostrarAcao: true,
      status: [
        { label: "Aguardando análise", value: 0 },
        { label: "Validada", value: 1 },
        { label: "Rejeitada", value: 2 },
      ],
      mostrar_financeiro: false,
      anos: [
        { label: "", value: "" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
        { label: "2025", value: "2025" }
      ],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      id_fornecedor: 0,
      mes: 0,
      ano: 0,
      fornecedores: [],
      financeiro: true,
      mostrar_planilha: true,
      total_end_id: 0,
      end_id_validado: 0,
      end_id_contestado: 0,
      valor_validado: 0,
      valor_contestado: 0,
      valor_total: 0,
      cabecalhoOperadora: [
        { header: "Site ID TIM", field: 'site_id_tim' },
        { header: "END ID TIM", field: 'end_id_tim' },
        { header: "Regional TIM", field: 'regional_tim' },
        { header: "UF", field: 'uf' },
        { header: "Detentora", field: 'detentora' },
        { header: "Site ID Detentora", field: 'site_id_detentora' },
        { header: "Nº SCI Inicial", field: 'n_sci_inicial' },
        { header: "CC (kWh)", field: 'cc_kwh' },
        { header: "CA (kWh)", field: 'ca_kwh' },
        { header: "Climatização (kWh)", field: 'climatizacao_kwh' },
        { header: "Energia Essencial (kWh)", field: 'energia_essencial_kwh' },
        { header: "Total de Consumo (kWh)", field: 'total_de_consumo_kwh' },
        { header: "Tarifa Utilizada Operadora", field: 'tarifa_utilizada_operadora' },
        { header: "Valor Total", field: 'valor_total' },
        { header: "Tipo Energia", field: 'tipo_de_energia' },
        { header: "É viável instalação Medidor TIM", field: 'viabilidade_instalacao_medidor_tim' },
        { header: "Justificativa", field: 'justificativa' },
        { header: "Observação Detentora", field: 'observacao_detentora' },
        { header: "Mês de Referência do Formulário", field: 'mes_ref_formulario' },
        { header: "Data de Envio do Formulário", field: 'data_envio_formulario' },
        { header: "Mês de Referência da Fatura", field: 'mes_ref_fatura' },
        { header: "Valor total da Fatura", field: 'valor_total_fatura' },
        { header: "Motivo Contestação", field: 'dados_contestacao_unificados' },
        
      ],
      cabecalhoTower: [
        { header: "Site ID TIM", field: 'site_id_tim' },
        { header: "END ID TIM", field: 'end_id_tim' },
        { header: "Regional TIM", field: 'regional_tim' },
        { header: "UF", field: 'uf' },
        { header: "Detentora", field: 'detentora' },
        { header: "Site ID Detentora", field: 'site_id_detentora' },
        { header: "Data RFI", field: 'data_rfi' },
        { header: "Tipo Energia", field: 'tipo_de_energia' },
        { header: "Consumo (kWh)", field: 'consumo_kwh' },
        { header: "É viável instalação Medidor TIM", field: 'viabilidade_instalacao_medidor_tim' },
        { header: "Justificativa", field: 'justificativa' },
        { header: "Observação Detentora", field: 'observacao_detentora' },
        { header: "Mês de Referência do Formulário", field: 'mes_ref_formulario' },
        { header: "Data de Envio do Formulário", field: 'data_envio_formulario' },
        { header: "Mês de Referência da Fatura", field: 'mes_ref_fatura' },
        { header: "Valor total da Fatura", field: 'valor_total_fatura' },
        { header: "Motivo Contestação", field: 'dados_contestacao_unificados' },
      ],
      tipoPlanilha: "",
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      planilhas: [],
      mostrarPlanilhaModal: false,
      unidades: 0,
      faturas: 0,
      loading: false,
      id_status: 0,
      id_planilha: 0,
      planilhas_importadas: 0,
      planilhas_validar: 0,
      planilhas_validadas: 0,
      planilhas_reprovadas: 0,
      planilhas_pagamento_aprovado: 0,
      planilhas_pagamento_rejeitado: 0,
      mostrar_blocos: true,
      mostrar_blocos_fornecedor: false,
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Sales",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      lineOptions: null,
    };
  },
  productService: null,
  themeChangeListener: null,
  mounted() {
    this.listaConsolidados();
    this.listarFornecedor();
    this.listarCardsFinanceiros();
    this.montarHome();
    
    this.productService
      .getProductsSmall()
      .then((data) => (this.products = data));

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
  },
  created() {
    this.productService = new ProductService();
  },
  components: {
    ProgressSpinner
  },
  computed: {
    cabecalhoModal() {

      if (this.tipoPlanilha === 'OPERADORA') {
        
        return this.cabecalhoOperadora;
      } else if (this.tipoPlanilha === 'TOWER') {
        
        return this.cabecalhoTower;
      }
      return [];
    }
  },
  methods: {
    formatValor(value) {
    if (value == null) {
      return 0;
    }
    const num = parseFloat(value);
    const valorConvertido = num / 100;
    return valorConvertido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    listarRelatorio(id) {
      let dados = { id: id };
      this.axios
        .post("/relatorios/dados-planilhas-importadas", dados)
        .then((response) => {
          this.planilha_fornecedor = response.data.dados;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    mostrarPlanilha2(planilha, mostrar) {
      if (mostrar == 2) {
        this.mostrarAcao = false;
      }
  
      this.planilha = planilha;
      this.tipoPlanilha = planilha.tipo;
      this.listarDadosPlanilhasImportadas(planilha.planilha_id, planilha.tipo);
      this.id_status = planilha.id_status;
      this.id_planilha = planilha.id;
      this.mostrarPlanilhaModal = true;
    },
    mostrarPlanilha(planilha, mostrar) {
      if (mostrar == 2) {
        this.mostrarAcao = false;
      }
      console.log(planilha);
      this.planilha = planilha;
      this.tipoPlanilha = planilha.tipo;
      this.listarDadosPlanilhasImportadas(planilha.planilha_id, planilha.tipo);
      this.id_status = planilha.id_status;
      this.id_planilha = planilha.id;
      this.mostrarPlanilhaModal = true;
    },
    montarHome() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.id_tipo == 22913699) {
        this.mostrar_blocos = false;
        this.mostrar_blocos_fornecedor = true;
        this.mostrar_planilha = false;
      }
      if (user.id_tipo == 22959227) {
        this.mostrar_blocos = true;
        this.mostrar_blocos_fornecedor = false;
        this.mostrar_planilha = false;
        this.mostrar_financeiro = true;
        this.listarInicial();
      }
    },
    listarInicial() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.id_tipo == 22959227) {
        let dados = {
          id_fornecedor: this.id_fornecedor,
          ano: this.ano,
          mes: this.mes
        };
        this.loading = true;
        this.axios
          .post("home/financeiro", dados)
          .then((response) => {
            this.planilhas_importadas = response.data.dados.planilhas_importadas;
            this.planilhas_validar = response.data.dados.planilhas_validar;
            this.planilhas_validadas = response.data.dados.planilhas_validadas;
            this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas;
            this.planilhas_pagamento_aprovado = response.data.dados.planilhas_pagamento_aprovado;
            this.planilhas_pagamento_rejeitado = response.data.dados.planilhas_pagamento_rejeitado;

            this.total_end_id = response.data.dados.qtd_endid_enviado;
            this.end_id_validado = response.data.dados.qtd_endid_aprovado;
            this.end_id_contestado = response.data.dados.qtd_endid_reprovado;
            this.valor_validado = response.data.dados.valor_endid_aprovado;
            this.valor_contestado = response.data.dados.valor_endid_reprovado;
            this.valor_total = response.data.dados.valor_total;

            this.planilhas = response.data.dados.planilhas;
            this.loading = false;
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
            this.loading = false;
          });
      } else {
        let dados = {
          id_fornecedor: this.id_fornecedor,
          ano: this.ano,
          mes: this.mes
        };
        this.loading = true;
        this.axios
          .post("home/inicial", dados)
          .then((response) => {
            this.planilhas_importadas = response.data.dados.planilhas_importadas;
            this.planilhas_validar = response.data.dados.planilhas_validar;
            this.planilhas_validadas = response.data.dados.planilhas_validadas;
            this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas;
            this.planilhas_pagamento_aprovado = response.data.dados.planilhas_pagamento_aprovado;
            this.planilhas_pagamento_rejeitado = response.data.dados.planilhas_pagamento_rejeitado;

            this.planilhas = response.data.dados.planilhas;
            this.loading = false;
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
            this.loading = false;
          });
      }
    },
    listaConsolidados() {
      this.loading = true;
      const dados = {
        id_fornecedor: this.id_fornecedor,
        mes: this.mes,
        ano: this.ano,
      };
      this.axios
        .post("/home/consolidado", dados)
        .then((response) => {
          this.planilhas = response.data.resultado;
          this.loading = false;
          console.log("Dados consolidados filtrados:", this.planilhas);
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Falha ao carregar os dados consolidados!",
            life: 3000,
          });
        });
    },
    listarCardsFinanceiros() {
      this.axios
        .post("home/cards")
        .then((response) => {
          // Considerando que o backend retorna os dados em response.data.resultado
          const cards = response.data.resultado;
          this.planilhas_importadas = cards.planilhas_importadas;
          this.planilhas_validar = cards.planilhas_para_validar;
          this.planilhas_validadas = cards.planilhas_validadas;
          this.planilhas_reprovadas = cards.planilhas_contestadas;
          this.planilhas_pagamento_aprovado = cards.pagamentos_contestadas_procedente;
          this.planilhas_pagamento_rejeitado = cards.pagamentos_contestadas_improcedente;
          // Caso haja outras propriedades, atribua conforme necessário.
        })
        .catch((error) => {
          console.error("Erro ao carregar dados financeiros:", error);
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Falha ao carregar os dados financeiros!",
            life: 3000,
          });
        });
    },
    listarDadosPlanilhasImportadas(planilha_id, tipo) {
      const dados = {
        id_importacao: planilha_id,
        tipo_upload: tipo
      };
      this.axios
        .post("/relatorios/dados-planilhas-importadas", dados)
        .then((response) => {
          console.log(response);
          this.planilha_fornecedor = response.data.dados;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#ebedef",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
          y: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
        },
      };
    },
    listarFornecedor() {
      this.axios
        .post("/fornecedores/listar")
        .then((response) => {
          let options = [];
          let desc_fornecedores = {};
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["razao_social"] };
            desc_fornecedores[el["id"]] = el["razao_social"];
            options.push(option);
          });
          this.fornecedores = options;
          this.desc_fornecedores = desc_fornecedores;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.p-datatable-thead,
.p-datatable-tbody {
  white-space: nowrap !important;
}
</style>
